import React from 'react'

import { useQuery } from '@apollo/client'
import Presentational from '@/screens/Insights/Articles/Overview/Presentational'
import Layout from '@/components/layout'

import { Screen } from '@/types'
import {
  GetArticleOverviewPageDocument,
  GetArticleOverviewPageQuery,
} from '@/queries/queries/Articles/getArticleOverviewPage.generated'

const Articles: React.FC<React.PropsWithChildren<Screen>> = ({ slug }) => {
  const { loading, data } = useQuery<GetArticleOverviewPageQuery>(GetArticleOverviewPageDocument, {
    variables: { id: slug },
  })

  return (
    <Layout.Default
      contactData={data?.modalContact?.content}
      departmentsContactData={data?.modalDepartmentsContact}
      departmentsNewsletterData={data?.modalDepartmentsNewsletter}
      donationData={data?.modalDonation}
      feedbackData={data?.modalFeedback?.content}
      footerData={data?.footer}
      loading={loading}
      newsletterData={data?.modalNewsletter?.content}
      seoData={data?.articleOverview?.content?.seo}
      siteData={data?.site}
    >
      <Presentational content={data?.articleOverview?.content} />
    </Layout.Default>
  )
}

export default Articles
