import React from 'react'
import { theme } from '@damen/ui'

import { SecondaryHeader } from '@/components/UI'

import { useBreadcrumb } from '@/hooks'
import Components from '@/components/Components'
import { BlokZone } from '@/components/UI/BlokZone'

interface Props {
  content: any
}

const Presentational: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { body, header } = content

  const breadcrumb = useBreadcrumb(header.breadcrumb?.title, header.breadcrumb?.link, 'white', theme.colors.marineBlack)

  return (
    <>
      <section>
        <SecondaryHeader
          content={{
            id: header.id,
            header: { ...header, breadcrumb },
          }}
        />
      </section>
      <BlokZone bloks={body} componentLookup={Components} />
    </>
  )
}

export default Presentational
