import React from 'react'
import { theme } from '@damen/ui'

import { useBreadcrumb } from '@/hooks'
import { ArticleHeader } from '@/components/UI'
import { BlokZone } from '@/components/UI/BlokZone'
import Components from '@/components/Components'

interface Props {
  content: any
}

const Presentational: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { body, header } = content

  const breadcrumb = useBreadcrumb(
    header.breadcrumb?.title,
    header.breadcrumb?.link,
    'marineBlack',
    theme.colors.marineBlack,
  )

  // TODO: Cleanup mapping by moving parts to Apollo and fix typing
  const mappedBody = body.map((item: any) => {
    let blok = item

    if (item?.__typename === 'ArticleBody') {
      const firstBodyContentItem = item.content.find(
        (articleBodyItem: any) => articleBodyItem.__typename === 'BodyContent',
      )

      blok = {
        ...item,
        content: item.content.map((articleBodyItem: any) => {
          if (articleBodyItem.id !== firstBodyContentItem.id) return articleBodyItem

          return {
            ...articleBodyItem,
            intro: true,
          }
        }),
        details: {
          author: header.author,
          readTime: header.readTime,
        },
        tags: content.tags,
        breadcrumbLink: header.breadcrumb?.link,
      }
    }

    if (item?.__typename === 'ArticleFeed') {
      blok = {
        ...item,
        max: 3,
      }
    }

    return blok
  })

  return (
    <>
      <ArticleHeader content={{ id: header.id, header: { ...header, breadcrumb } }} />
      <BlokZone bloks={mappedBody} componentLookup={Components} />
    </>
  )
}

export default Presentational
