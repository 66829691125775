import React from 'react'

import { useQuery } from '@apollo/client'
import Presentational from '@/screens/Insights/Articles/Detail/Presentational'
import Layout from '@/components/layout'

import { Screen } from '@/types'

import { StructuredData } from './structured-data'
import {
  GetArticleDetailPageDocument,
  GetArticleDetailPageQuery,
} from '@/queries/queries/Articles/getArticleDetailPage.generated'

const ArticlesDetail: React.FC<React.PropsWithChildren<Screen>> = ({ slug }) => {
  const { loading, data } = useQuery<GetArticleDetailPageQuery>(GetArticleDetailPageDocument, {
    variables: { id: slug },
  })

  return (
    <Layout.Default
      contactData={data?.modalContact?.content}
      departmentsContactData={data?.modalDepartmentsContact}
      departmentsNewsletterData={data?.modalDepartmentsNewsletter}
      donationData={data?.modalDonation}
      feedbackData={data?.modalFeedback?.content}
      footerData={data?.footer}
      loading={loading}
      newsletterData={data?.modalNewsletter?.content}
      seoData={data?.articleDetail?.content?.seo}
      structuredData={StructuredData({
        contactDetails: data?.modalContact?.content?.contactDetails,
        headerDetails: data?.articleDetail?.content?.header,
      })}
      headerVariant="dark"
      siteData={data?.site}
    >
      <Presentational content={data?.articleDetail?.content} />
    </Layout.Default>
  )
}

export default ArticlesDetail
