import { useBasePath } from '@/hooks'

export const StructuredData = ({ contactDetails = null, headerDetails = null }) => {
  const { url } = useBasePath()
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    headline: headerDetails?.title,
    description: headerDetails?.intro,
    image: headerDetails?.img?.filename,
    author: {
      '@type': 'Organization',
      name: headerDetails?.author,
    },
    publisher: {
      '@type': 'Organization',
      name: contactDetails?.seoData?.companyName,
      logo: {
        '@type': 'ImageObject',
        url: contactDetails?.seoData?.companyLogo,
      },
    },
    datePublished: headerDetails?.formattedDate,
  }
}
